var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { web3 } from '@project-serum/anchor';
export const sendTransaction = (provider, instructions, cleanUpInstructions, signers, skipPreflight = true, simulate = false) => __awaiter(void 0, void 0, void 0, function* () {
    let txId = '';
    const transaction = new web3.Transaction();
    transaction.add(...instructions, ...cleanUpInstructions);
    transaction.feePayer = provider.wallet.publicKey;
    transaction.recentBlockhash = (yield provider.connection.getRecentBlockhash()).blockhash; // need to use old method or upgrade @solana/spl-token to 0.2.0
    if (simulate) {
        const res = yield provider.simulate(transaction, signers);
        console.log('Simulates response', res);
    }
    txId = yield provider.sendAndConfirm(transaction, signers, {
        skipPreflight
    });
    return { txId, transaction };
});
